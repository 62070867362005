import 'slick-carousel';

jQuery(async function () {
	$('.home .slick-carousel').slick({
		dots: false,
		arrows: true,
		infinite: false,
		autoplay: false,
		speed: 300,
		slidesToShow: 3,
		touchThreshold: 1000,
		slidesToScroll: 1,
		lazyLoad: 'ondemand',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}/*,
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			*/
		]
	});
});